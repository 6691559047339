
window._ = require('lodash');

window._.mixin({
    pluck: _.map
})

/**
 * Generate a true UNIQUE ID (check dom too)
 * @param prefix
 * @returns {*}
 */
_.domUniqueID = function (prefix) {
    var id = prefix + _.random(0, 999999);
    if ($('#' + id).length > 0) {
        return _.domUniqueID(prefix);
    }

    return id;
};

/**
 * Clone DEEP data
 * @param data
 * @returns {any}
 */
_.cloneDeep = function (data) {
    return JSON.parse(JSON.stringify(data));
};

/**
 * Check if we can access path from an object
 * @param o Object
 * @param s Path
 * @returns {*}
 */
_.accessStr = function (o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (_.isNull(o) || _.isUndefined(o)) {
            return;
        }
        if (k in o) {
            if (!_.has(o, k)) {
                return;
            }
            o = o[k];
        } else {
            return;
        }
    }
    return o;
};


/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

/*try {
    window.$ = window.jQuery = require('jquery');

    require('bootstrap-sass');
} catch (e) {}*/

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

/*window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';*/

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

/*let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}*/

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo'

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: 'your-pusher-key'
// });
