<template>
    <div>

      <form @submit.prevent="checkForm" action="/handle-register" method="post" novalidate="true">

        <div v-if="step === 1">

          <div class="row">
            <div class="col-sm-6">
              <!-- Connecting server -->
              <div v-if="connectingServer" class="loader"></div>
              <div v-if="!connectingServer" class="m-t-0 m-b-2 font-weight-semibold text-muted">
                Registration
              </div>
            </div>
            <div class="col-sm-6">
              <h5 class="m-t-0 m-b-2 font-weight-semibold text-muted pull-right">Step 1 of 3</h5>
            </div>
            <div class="col-sm-8">
              Please complete the following information so we can create your account.
            </div>
            <div class="col-sm-4">
              <div class="pull-right">
                <a href="/login" style="font-size:12px">Back to Login</a>
              </div>
            </div>
          </div>

          <hr>

          <div class="row">
            <!-- <div class="col-sm-12">
              <input
                id="name"
                type="text"
                placeholder="Username"
                class="form-control"
                name="name"
                v-model="registration.name"
                required>
            </div> -->

            <div class="col-sm-12">
              <input
                id="email"
                type="email"
                placeholder="Email"
                class="form-control"
                name="email"
                v-model="registration.email"
                required>
            </div>

            <hr>

            <div class="col-sm-12">
              <input
                id="confirm_email"
                type="email"
                placeholder="Confirm Email"
                class="form-control"
                name="confirm_email"
                v-model="registration.confirm_email"
                required>
            </div>
          </div>

          <br>

          <div class="row">
            <div class="col-sm-6">
              <input
                id="first_name"
                type="text"
                placeholder="First Name"
                class="form-control"
                name="first_name"
                v-model="registration.first_name"
                required>
            </div>
            <div class="col-sm-6">
              <input
                id="last_name"
                type="text"
                placeholder="Last Name"
                class="form-control"
                name="last_name"
                v-model="registration.last_name"
                required>
            </div>
          </div>

          <hr>

          <div class="row">
            <div class="col-sm-6">

            </div>
            <div class="col-sm-6">
              <button class="btn btn-info pull-right" @click.prevent="next()" :disabled="connectingServer">Next</button>
            </div>
          </div>

        </div>

        <div v-if="step === 2">

          <div class="row">
            <div class="col-sm-6">
              <!-- Connecting server -->
              <div v-if="connectingServer" class="loader"></div>
              <div v-if="!connectingServer" class="m-t-0 m-b-2 font-weight-semibold text-muted">
                Registration
              </div>
            </div>
            <div class="col-sm-6">
              <h5 class="m-t-0 m-b-2 font-weight-semibold text-muted pull-right">Step 2 of 3</h5>
            </div>
            <div class="col-sm-8">
              Please complete the following information so we can create your account.
            </div>
            <div class="col-sm-4">
              <div class="pull-right">
                <a href="/login" style="font-size:12px">Back to Login</a>
              </div>
            </div>
          </div>

          <hr>

          <div class="row">
            <div v-for="sys_hierarchy in registration.sys_hierarchies"
              v-if="sys_hierarchy.reg_user_attribute.onoff"
              class="col-sm-6">
              <select
                class="form-control"
                v-model="sys_hierarchy.registration_value"
                style="margin-bottom:25px;"
                required>
                <option disabled value="">{{sys_hierarchy.name}}</option>
                <option v-for="option in sys_hierarchy.reg_user_attribute.options" :value="option.value">{{option.value}}</option>
              </select>
            </div>

            <div v-for="sys_additional_ufield in registration.sys_additional_ufields"
              v-if="sys_additional_ufield.reg_user_attribute.onoff"
              class="col-sm-6">
              <select
                class="form-control"
                v-model="sys_additional_ufield.registration_value"
                style="margin-bottom:25px;"
                required>
                <option disabled value="">{{sys_additional_ufield.name}}</option>
                <option v-for="option in sys_additional_ufield.reg_user_attribute.options" :value="option.value">{{option.value}}</option>
              </select>
            </div>
          </div>

          <hr>

          <div class="row">
            <div class="col-sm-6">
              <button class="btn btn-info" @click.prevent="prev()" :disabled="connectingServer">Prev</button>
            </div>
            <div class="col-sm-6">
              <button class="btn btn-info pull-right" @click.prevent="next()" :disabled="connectingServer">Next</button>
            </div>
          </div>

        </div>

        <div v-if="step === 3">

          <div class="row">
            <div class="col-sm-6">
              <!-- Connecting serve"r -->
              <div v-if="connectingServer" class="loader"></div>
              <div v-if="!connectingServer" class="m-t-0 m-b-2 font-weight-semibold text-muted">
                Registration
              </div>
            </div>
            <div class="col-sm-6">
              <h5 class="m-t-0 m-b-2 font-weight-semibold text-muted pull-right">Step 3 of 3</h5>
            </div>
            <div class="col-sm-8">
              Please complete the following information so we can create your account.
            </div>
            <div class="col-sm-4">
              <div class="pull-right">
                <a href="/login" style="font-size:12px">Back to Login</a>
              </div>
            </div>
          </div>

          <hr>

          <div class="row">
            <div class="col-sm-6">
              <input id="password" type="password" class="form-control" v-model="registration.password" placeholder="Password" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" required>
            </div>
            <div class="col-sm-6">
              <input id="confirm_password" type="password" class="form-control" v-model="registration.confirm_password" placeholder="Confirm Password" required>
            </div>
          </div>

          <hr>

          <div class="row">
            <div class="col-sm-6">
              <button class="btn btn-info" @click.prevent="prev()" :disabled="connectingServer">Prev</button>
            </div>
            <div class="col-sm-6">
              <input class="btn btn-info pull-right" type="submit" value="Save" :disabled="connectingServer">
            </div>
          </div>

        </div>
      </form>


      <!-- Modal -->
      <div id="validationErrorsModal" class="modal fade" role="dialog">
        <div class="modal-dialog">

          <!-- Modal content-->
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal">&times;</button>
              <h4 class="modal-title">Validation Errors</h4>
            </div>
            <div class="modal-body">
              <p v-if="errors.length">
                <b>Please correct the following error(s):</b>
                <ul>
                  <li v-for="error in errors" style="color:#8a0000;">{{ error }}</li>
                </ul>
              </p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            </div>
          </div>

        </div>
      </div>


      <!-- Modal -->
      <div id="somethingWentWrongModal" class="modal fade" role="dialog">
        <div class="modal-dialog">

          <!-- Modal content-->
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal">&times;</button>
              <h4 class="modal-title">Error</h4>
            </div>
            <div class="modal-body">
              <h3 class="m-t-0 m-b-2 font-weight-semibold text-muted pull-right">Email address already registered</h3>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            </div>
          </div>

        </div>
      </div>


      <!-- Trigger the modal with a button -->
      <button id="triggerValidationErrorsModal" type="button" class="btn btn-info btn-lg" style="display:none" data-toggle="modal" data-target="#validationErrorsModal">Open Modal</button>

      <!-- Trigger the modal with a button -->
      <button id="triggerSomethingWentWrongModal" type="button" class="btn btn-info btn-lg" style="display:none" data-toggle="modal" data-target="#somethingWentWrongModal">Open Modal</button>


    </div>
</template>

<script>

export default{
    props: [
      'system_hierarchies',
      'system_additional_ufields',
      'system_allowed_domains'
    ],

    data: function () {
        return {
          some_var: '',

          step:1,
          errors: [],
          registration:{
            name:'',
            email:'',
            confirm_email:'',
            first_name:'',
            last_name:'',
            password:'',
            confirm_password:'',
            is_allowed: false,
            manager:'',

            sys_hierarchies:{},
            sys_additional_ufields: {},
            sys_allowed_domains: {},

          },

          csrf: $('meta[name="csrf-token"]').attr('content'),
          sys_hierarchies: {},
          sys_additional_ufields: {},
          sys_allowed_domains: {},

          connectingServer: false,

        }
    },

    computed: {

    },

    components: {

    },

    methods: {

      someMethod() {

      },

      prev() {
        this.step--;
      },

      next() {
        this.step++;
      },

      checkForm: function(e){

        this.errors = [];
        this.connectingServer = true;
        this.registration.name = this.registration.email;

        if (!this.registration.name) {
          this.errors.push('Email is required.');
        }

        let path = '/valid-username';
        this.$http.get(path, {
          params: {
            username: this.registration.name,
          }
        }).then(res => {
          // console.log(res);
          this.connectingServer = false;
          if(res.body.username.unique){
            this.checkForm_part2();
          }else{
            this.errors.push('This email is already being used, you must choose another.');
            this.checkForm_part2();
          }
        }).catch(function (err) {
          // console.log(err);
          this.connectingServer = false;
          this.errors.push("Network problems. Impossible to verify username.");
          this.checkForm_part2();
        });

      },


      checkForm_part2: function(){

        if (!this.registration.email) {
          this.errors.push('You must provide a valid email.');
        }else if (!this.validEmail(this.registration.email)) {
          this.errors.push('Valid email required.');
        }else{
          let domain_value = this.registration.email.split("@")[1];
          this.registration.is_allowed = false;
          this.sys_allowed_domains.forEach((sys_allowed_domain, i) => {
            if(sys_allowed_domain.value == domain_value){
              this.registration.is_allowed = true;
              this.registration.manager = sys_allowed_domain.manager;
            }
          });
        }

        if (!this.registration.confirm_email) {
          this.errors.push('Confirm email id required.');
        }else if (this.registration.email != this.registration.confirm_email) {
          this.errors.push("Email and confirm email don't match.");
        }

        if (!this.registration.first_name) {
          this.errors.push('First name is required.');
        }

        if (!this.registration.last_name) {
          this.errors.push('Last name is required.');
        }

        this.registration.sys_additional_ufields.forEach((sys_additional_ufield, i) => {
          if (sys_additional_ufield.reg_user_attribute.onoff) {
            if (!sys_additional_ufield.registration_value) {
              this.errors.push(sys_additional_ufield.name+' option is required.');
            }
          }
        });

        this.registration.sys_hierarchies.forEach((sys_hierarchy, i) => {
          if (sys_hierarchy.reg_user_attribute.onoff) {
            if (!sys_hierarchy.registration_value) {
              this.errors.push(sys_hierarchy.name+' option is required.');
            }
          }
        });

        if (!this.registration.password) {
          this.errors.push('Password id required.');
        }else if (!this.validPassword(this.registration.password)) {
          this.errors.push('Password must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters.');
        }

        if (!this.registration.confirm_password) {
          this.errors.push('Confirm password id required.');
        }else if (this.registration.password != this.registration.confirm_password) {
          this.errors.push("Password and confirm password don't match.");
        }

        if (this.errors.length == 0) {
          this.submitForm();
        }else{
          $("#triggerValidationErrorsModal").click();
        }

      },


      validEmail: function (email) {
        var pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(email);
      },


      validPassword: function (password) {
        // var pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;
        var pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*?]{6,}$/;
        return pattern.test(password);
      },


      submitForm(){
        this.connectingServer = true;
        let path = '/handle-register';
        let headers = {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        };
        this.$http.post(path, {
          registration: this.registration
        }, {
          headers: headers
        }).then(res => {
          // console.log(res);
          this.connectingServer = false;
          if (res.body.success) {
            if(res.body.status == "pending"){
              window.location.href = "/registration-feedback?message=pending";
            }else{
              window.location.href = "/registration-feedback?message=created";
            }
          }else{
            $("#triggerSomethingWentWrongModal").click();
          }
        }).catch(function (err) {
          console.log(err);
          this.connectingServer = false;
          $("#triggerSomethingWentWrongModal").click();
        });
      },


    },

    created: function () {

      this.sys_hierarchies = JSON.parse(this.system_hierarchies);
      this.sys_hierarchies.forEach((sys_hierarchy, i) => {
        sys_hierarchy.registration_value = '';
        sys_hierarchy.reg_user_attribute.options.forEach((option, i) => {
          if (option.is_default==1 && sys_hierarchy.reg_user_attribute.onoff==0) {
            sys_hierarchy.registration_value = option.value;
          }
        });

      });

      this.sys_additional_ufields = JSON.parse(this.system_additional_ufields);
      this.sys_additional_ufields.forEach((sys_additional_ufield, i) => {
        sys_additional_ufield.registration_value = '';
        sys_additional_ufield.reg_user_attribute.options.forEach((option, i) => {
          if (option.is_default==1 && sys_additional_ufield.reg_user_attribute.onoff==0) {
            sys_additional_ufield.registration_value = option.value;
          }
        });

      });

      this.sys_allowed_domains = JSON.parse(this.system_allowed_domains);

      this.registration.sys_hierarchies = this.sys_hierarchies;
      this.registration.sys_additional_ufields = this.sys_additional_ufields;
      this.registration.sys_allowed_domains = this.sys_allowed_domains;

      console.log(this.sys_hierarchies);
      console.log(this.sys_additional_ufields);
      console.log(this.sys_allowed_domains);


    },

    mounted: function () {

    },

    watch: {
      some_var(old,val){
        // console.log(old, val);
      },


    }

}
</script>

<style>
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
